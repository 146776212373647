@use '@angular/material' as mat;
@import "./common.scss";

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$candy-app-primary: mat.define-palette(mat.$gray-palette);
$candy-app-accent: mat.define-palette(mat.$blue-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$candy-app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$candy-app-theme: mat.define-light-theme(
  $candy-app-primary,
  $candy-app-accent,
  $candy-app-warn
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($candy-app-theme);

:root {
  --couleurPrimaire: #cc0000;
}
