@use '@angular/material' as mat;
.flex-al-droite {
  display: flex;
  justify-content: flex-end;
}

.clear {
  content: "";
  clear: both;
}

// @import "~bootstrap/scss/bootstrap-reboot";
// ::ng-deep {
//     .bootstrapImportation {
//     @import "~bootstrap/scss/bootstrap";
//     }
// }
.bootstrapImportation {
  @import "~bootstrap/scss/bootstrap";
}
.cdk-overlay-pane.photos > mat-dialog-container {
  padding: 0;
}
@import "@angular/material/theming";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();
